'use strict';

import { createRouter, createWebHistory } from 'vue-router';

// Import the components for the routes
import Dashboard from '../components/dashboard.vue';
import Profile from '../components/profile.vue';

const routes = [
  { path: '/', name: 'Dashboard', component: Dashboard },
  { path: '/profile', name: 'Profile', component: Profile },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(function (to, from, next) {
  next();
});

export default router;
