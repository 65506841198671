<template>
  <header class="flex flex-col p-6">
    <div class="relative flex flex-shrink-0">
      <div class="flex flex-1 justify-between">
        <div class="flex">
          <a href="/" class="mr-6">
            <logo classes="h-12 w-auto"></logo>
          </a>
        </div>
        <div class="flex">
          <router-link to="/" class="px-3 py-3 text-sm font-medium">
            Dashboard
          </router-link>
          <a v-if="isAdmin" href="/admin" class="px-3 py-3 text-sm font-medium">
            Admin
          </a>
          <div
            class="separator mt-[9px] mx-2 h-6 border-l border-slate-400"
          ></div>
          <div class="relative" v-click-outside="closeAccount">
            <button
              class="flex px-3 py-3 text-sm font-medium"
              @click="toggleExpand('account')"
            >
              <span>Account</span>
              <svg
                aria-hidden="true"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                class="mt-1 ml-2 size-3 stroke-slate-400"
              >
                <path
                  d="M9.75 4.125 6 7.875l-3.75-3.75"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </button>
            <div
              v-if="expanded.account"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="user-menu-button"
              tabindex="-1"
              class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transition ease-in duration-100 transform opacity-100 scale-100 animate-fade-scale-in"
            >
              <router-link
                aria-current="page"
                class="block px-4 py-2 text-sm font-medium"
                role="menuitem"
                tabindex="-1"
                to="/profile"
                @click.native="closeAccount"
              >
                My Profile
              </router-link>
              <button
                class="block px-4 py-2 text-sm font-medium"
                @click="$root.signOut($event)"
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from './logo.vue';

export default {
  components: {
    Logo,
  },
  props: {
    isAdmin: { type: Boolean, default: false },
  },
  data: function () {
    return {
      expanded: {},
    };
  },
  methods: {
    toggleExpand: function (id) {
      this.expanded[id] = !this.expanded[id];
    },
    expand: function (id) {
      this.expanded[id] = true;
    },
    unExpand: function (id) {
      console.log('unExpand', id);
      this.expanded[id] = false;
    },
    closeAccount: function () {
      this.expanded.account = false;
    },
  },
  mounted: function () {},
};
</script>
