'use strict';

import '../css/style.css';
import * as Vue from 'vue';
import Loading from './components/loading.vue';
import AppHeader from './components/app-header.vue';
import Dashboard from './components/dashboard.vue';
import F from './lib/fetch.js';
import delay from './lib/delay.js';
import router from './router/index.js';

const app = Vue.createApp({
  data: function () {
    return {
      showLoading: false,
      showApp: false,
      me: null,
    };
  },
  components: {
    Loading,
    AppHeader,
    Dashboard,
  },
  methods: {
    getMe: async function () {
      let vm = this;
      let resp = await F.fetch('/api/employees/me');
      let me = await resp.json();
      vm.me = me;
    },
    toSignin: function () {
      localStorage.setItem('returnTo', '/');
      localStorage.setItem('userNeedsSignIn', true);
      window.location.href = '/signin';
    },
    signOut: async function ($event) {
      if ($event) {
        $event.preventDefault();
      }
      let resp = await fetch('/api/authn/session', { method: 'DELETE' });
      if (resp.ok) {
        window.location.href = '/signin';
      }
    },
  },
  computed: {
    legalName: function () {
      let vm = this;

      return [
        vm.me.foundation.first_name,
        vm.me.foundation.middle_initial,
        vm.me.foundation.last_name,
      ]
        .filter(Boolean)
        .join(' ');
    },
    preferredName: function () {
      let vm = this;

      if (vm.me.google) {
        return vm.me.google.name.fullName;
      }

      if (vm.me.foundation) {
        return vm.foundationName;
      }

      return null;
    },
    companyEmail: function () {
      let vm = this;

      if (!vm.me.google) {
        return null;
      }

      let emails = vm.me.google.emails;

      let primaryEmail = emails.find(function (el) {
        return el.primary;
      });

      return primaryEmail.address;
    },
    personalEmail: function () {
      let vm = this;

      if (!vm.me.foundation) {
        return null;
      }

      return vm.me.foundation.email_address;
    },
    managerEmail: function () {
      let vm = this;

      try {
        let manager = vm.me.google.relations.find(function (el) {
          return el.type === 'manager';
        });
        return manager.value;
      } catch (err) {
        return null;
      }
    },
  },
  created: async function () {
    let vm = this;

    vm.showLoading = true;

    let idToken = await F.getIdToken().catch(function (err) {
      if (err.code === 'E_SESSION_INVALID') {
        return '';
      }
      throw err;
    });
    if (!idToken) {
      await delay(500);
      vm.toSignin();
      return;
    }

    await vm.getMe();

    Object.assign(vm, {
      showLoading: false,
      showApp: true,
    });
  },
});

app.directive('click-outside', {
  beforeMount(el, binding) {
    el.clickOutsideHandler = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.addEventListener('click', el.clickOutsideHandler);
  },
  unmounted(el) {
    document.removeEventListener('click', el.clickOutsideHandler);
  },
});

app.use(router);

app.mount('#app');

app.config.errorHandler = async function (err, vm, info) {
  // Unhandled unauthorized request
  if (err.resp?.status === 401) {
    void (await vm.$root.signOut());
    return;
  }

  console.log(err);

  // TODO Send error to an external service

  // TODO Alert that users should never see
  window.alert(
    'An unexpected error has occurred in the application. Please refresh and try again. If the error persists, contact support at app@cogburnbros.com for help.'
  );

  // void (await vm.$root.signOut());
};
