<template>
  <section class="dashboard-nav-section app-card mb-12 md:mb-6">
    <h2 class="text-xl font-medium text-red-800 mb-3">{{ title }}</h2>
    <div class="space-y-2">
      <slot></slot>
    </div>
  </section>
</template>

<style scoped>
.dashboard-nav-section :deep(a.link) {
  display: block;
  @apply text-gray-600;
  @apply hover:text-red-800;
}
</style>

<script>
export default {
  name: 'NavSection',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
